import { BookingAuthority } from "../../Services/BookingEntities";
import { SetErrorMessages } from "../Utils/CommonHelpers";
import { ErrorResult } from "../Utils/ErrorEntities";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { LogBookingCreation } from "../Booking/LogBookingCreation";
import { BuildBookingRequest } from "./BuildBookingRequest";
import { LoadNewOdrdTripId } from "./LoadNewOdrdTripId";
import { ICreateBooking } from "./ICreateBooking";
import { LoadMyRecentTrips, TrackBookingHelper } from "./BookingLoaders";

/** Create Booking by using the V1b create booking endpoint */
export class CreateBookingV1 implements ICreateBooking {

    /** The booking ID, defined when the API call suceeds */
    NewBookingId: BookingAuthority | null = null;

    /** The error, defined when the API call fails */
    Error: ErrorResult | null = null;

    constructor(readonly withSmsVerification: boolean) { }

    /** Create a booking by transformation the model to entity and call Booking Service */
    async CreateBooking(): Promise<boolean> {
        const createBookingEntity = BuildBookingRequest(this.withSmsVerification);
        const isFixedFareBooking = !!createBookingEntity.Payment.IsFixedFare;

        var serviceResult = await Api.Booking.CreateBookingV1b(createBookingEntity);
        Dispatch.UILogicControl.BookingFormApiEnd();

        if (serviceResult.isSuccess) {

            LoadNewOdrdTripId();
            LogBookingCreation(isFixedFareBooking);
            this.NewBookingId =  serviceResult.value;
            return true;
        }
        else {
            this.Error = SetErrorMessages(serviceResult);
            return false;
        }
    }

    /** Refresh Tracking and Recent Trips data based on the booking */
    async ProcessBooking(): Promise<void> {
        await TrackBookingHelper(this.NewBookingId!.bookingId, this.NewBookingId!.hashCode);

        // Refresh the list of recent trips along with the newly created booking
        LoadMyRecentTrips();
    }

    /** Returns the ErrorResult */ 
    GetError(): ErrorResult{
        return this.Error!;
    }
}