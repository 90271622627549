import React from "react";
import { Dialog2State } from "../Redux/Dialog2State";
import { ApplicationState } from "../../../appState";
import { connect } from "react-redux";
import { DialogKind } from "../../Dialog/DialogEntities";
import { DialogHost } from "./DialogHost";
import "../../Dialog/Dialog.scss";
import { DialogLoading } from "../../Dialog/DialogLoading";

/** UI that renders the Dialog (V2) wrapper. */
class DialogWrapperCore extends React.Component<Dialog2State> {

    render() {

        const dialog = this.props.ActiveDialog;

        // quick exit: none open
        if (dialog == null) return null;

        const dialogClass = this.GetDialogClass(dialog);

        const dialogContentStyle = (dialog == DialogKind.SimpleErrorMessaging) || (dialog == DialogKind.RetryErrorMessaging) ? "transparent-background" : "dialog-content";

        return (
            <div className="dialog-feature-root">
                <DialogLoading />
                <div className="dialog-background" />
                <div className={dialogClass}>
                    <div className={dialogContentStyle}>                     
                        <DialogHost {...this.props} />
                    </div>
                </div>
            </div>
        );
    }

    GetDialogClass(kind: DialogKind) {

        if (kind == DialogKind.LocationChooser) return "dialog-popup small-popup";

        if (kind == DialogKind.SimpleErrorMessaging || kind == DialogKind.RetryErrorMessaging) return "dialog-popup simple-error-message-popup";

        if (kind == DialogKind.SignUp || kind == DialogKind.SignUpToAddCard) return "dialog-popup credential-popup-common signup-popup";

        if (kind == DialogKind.LogIn) return "dialog-popup credential-popup-common login-popup";
        
        if (kind == DialogKind.ForgotPassword) return "dialog-popup credential-popup-common forgot-password-popup";

        if (kind == DialogKind.PriceGuaranteeFAQ) return "dialog-popup credential-popup-common PriceGuarantee-FAQ-popup";

        // Wider popup for contact details.
        return "dialog-popup large-popup";
    }
}

function GetMyPropsFromStoreState(state: ApplicationState): Dialog2State {
    return {
        ...state.dialog2,
    };
}

/** UI that renders the Dialog (V2) components. */
export const DialogWrapper = connect(GetMyPropsFromStoreState)(DialogWrapperCore);