import { PaymentCard } from "../../Services/PaymentEntities";

/**
 * MpsPaymentCard maps to the payment card(credit/cabcharge) in MPS system
 * It is used to type the response from add card functionality.
 * The difference between MpsPaymentCard and PaymentCard is that MpsPaymentCard doesn't have the additional fields like(IsDefault,Isblocked etc)
 * Also, there won't be a cash option
 */
export interface MpsPaymentCard {
    /** CardId - It is unique Id for a card*/
    CardId: string;

    /** CardNumber - It is the number of a card(****123)*/
    CardNumber: string;

    /** CardType - Visa/Mastercard/Cabcharge etc*/
    CardType: string;

    /** CardName - Name of the card which user sets while adding card, It is not always unique*/
    CardName: string;

    /** CardExpiry - expiry month/year for card*/
    CardExpiry: string;
}

/**
 * Payment option details appearing on the payment method dropdown 
 */
export interface PaymentOption {

    /** Unique identifier for a payment option */
    Id: string;

    /** Display name of the payment option e.g. "Paying the Driver directly", "SATSS", etc. */
    Name: string;

    /** Type of payment options */
    Kind: PaymentOptionKind;

    /** Default payment option of the user */
    IsDefault: boolean;

    /** Populated for payment options that represent cards */
    Card?: PaymentCard;

    /** "Visa", "Mastercard", "Cash/ETFPOS", etc.  */
    Type: string;
}

/** 
 * Denotes the payment option used for a booking 
 */
export enum PaymentOptionKind {
    Card = "Card",
    SatssVoucher = "SatssVoucher",
    PayDriverDirectly = "PayDriverDirectly"
}

/** Different errors related to payment cards (e.g: card is removed from the account, expired, blocked etc.) */
export enum PaymentCardErrorType {
    /** Card added to the selected booking (from history) is not in the list of user's cards (i.e. card is removed from the account) */
    CardNotFound = "Card not found",

    /** No payment method selected while making a booking  */
    CardNotSelected = "Please select a payment method",

    CardNotProvidedForPriceGuarantee = "Card payment required when selecting a 'Fixed Price' fare",

    /** Selected card is expired. */
    CardExpired = "Card Expired"
}

/** "Paying the Driver directly" as a payment option */
export const PayDriverOption: PaymentOption = {
    Id: "Cash/ETFPOS",
    IsDefault: false,
    Type: "Cash/ETFPOS",
    Name: "Paying the Driver directly",
    Kind: PaymentOptionKind.PayDriverDirectly
};

/** SATSS (South Australian Taxi Subsidy Scheme) voucher as a payment option */
export const SatssVoucherOption: PaymentOption = {
    Id: "SATSS",
    Name: "SATSS",
    IsDefault: false,
    Type: "SATSS",
    Kind: PaymentOptionKind.SatssVoucher
}

/** Payment help text for the selected payment option */
export const PaymentHelpText = {
    
    PayDriverDirectly: "'Fixed Price' must be switched off above when selecting 'Paying the driver directly'",
    
    SatssVoucher: "Provide a subsidy voucher to the driver.",
    
    Card: "Payment will occur automatically using this card at trip end."
}

/** Contains the list of payment options to be displayed on the my wallet list */
export const WalletPaymentOptions: PaymentOptionKind[] = [
    PaymentOptionKind.Card,
    PaymentOptionKind.PayDriverDirectly 
];