import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { VerificationState, InitVerificationState } from "./VerificationState";
import { CountryInfo, VerificationTrigger } from "./VerificationEntities";

const slice = new ReduxStoreSlice<VerificationState>("Verification", InitVerificationState);

/** Dispatcher for actions in the Verification state slice. */
export const VerificationDispatchV2 = {

    /** The user enters their preferred contact number. */
    ContactNumber: slice.Action("Contact Number", ContactNumber),

    /** The user picks the country of their contact number. */
    CountryInfo: slice.Action("Country Info", CountryInfoFn),

    /** Clear all details of the user's contact number. */
    ClearContactNumber: slice.EmptyAction("Clear Contact Number", ClearContactNumber),

    /** Show the spinner UI in the Contact Details form while an API call is in progress. */
    ShowLoaderInContactDetails: slice.EmptyAction("Show Loader In Contact Details", ShowLoaderInContactDetails),

    /** Hide the spinner UI in the Contact Details form after an API call has finished. */
    HideLoaderInContactDetails: slice.EmptyAction("Hide Loader In Contact Details", HideLoaderInContactDetails),

    /** Set the error message about the contact number to be displayed in the booking widget. Do NOT pass an empty string; call ClearContactNumberError instead. */
    ContactNumberErrorMessage: slice.Action("Contact Number Error Message", ContactNumberErrorMessage),

    /** Remove any error message associated with the contact number. */
    ClearContactNumberError: slice.EmptyAction("Clear Contact Number Error", ClearContactNumberError),

    /** Indicate which UI started the verification flow: Booking or Signup. */
    WasTriggeredBy: slice.Action("Was Triggered By", WasTriggeredBy),

    /** Save the verification code after the user is successfully verified */
    SetVerificationCode: slice.Action("Save the verification code", SetVerificationCode),

    /** Remove the verification code, after the new registered user has successful completed the signup process */
    ClearVerificationCode: slice.EmptyAction("Clear the verification code", ClearVerificationCode),

    /** A new SMS Challenge has started with the specified ID */
    ChallengeStarted: slice.Action("Challenge Started", ChallengeStarted)
};

/** Reducer for the Verification store slice */
export const VerificationReducerV2 = slice.MakeCombinedReducer();

/** The user enters their preferred contact number. */
function ContactNumber(state: VerificationState, phoneNumber: string): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: { ...state.UserContactNumberInfo, Contactnumber: phoneNumber },
    };
}

/** The user picks the country of their contact number. */
function CountryInfoFn(state: VerificationState, payload: CountryInfo): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: {
            ...state.UserContactNumberInfo,
            CountryInfo: { ...state.UserContactNumberInfo.CountryInfo, ...payload }
        }
    };
}

/** Clear all details of the user's contact number. */
function ClearContactNumber(state: VerificationState): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: {}
    };
}

/** Show the spinner UI in the Contact Details form while an API call is in progress. */
function ShowLoaderInContactDetails(state: VerificationState): VerificationState {
    return {
        ...state,
        IsLoaderShownInContactDetails: true,
    };
}

/** Hide the spinner UI in the Contact Details form after an API call has finished. */
function HideLoaderInContactDetails(state: VerificationState): VerificationState {
    return {
        ...state,
        IsLoaderShownInContactDetails: false,
    };
}

/** Set the error message about the contact number to be displayed in the booking widget. */
function ContactNumberErrorMessage(state: VerificationState, errorMessage: string): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: { ...state.UserContactNumberInfo, ErrorMessage: errorMessage }
    };
}

/** Remove any error message associated with the contact number. */
function ClearContactNumberError(state: VerificationState): VerificationState {

    // split error message from other bits
    const { ErrorMessage, ...Remain } = state.UserContactNumberInfo;

    return {
        ...state,
        UserContactNumberInfo: Remain,
    };
}

/** Indicate which UI started the verification flow: Booking or Signup. */
function WasTriggeredBy(state: VerificationState, trigger: VerificationTrigger): VerificationState {
    return {
        ...state,
        Trigger: trigger,
    };
}

/** Save the verification code after the user is successfully verified */
function SetVerificationCode(state: VerificationState, payload: string): VerificationState {
    return {
        ...state,
        VerificationCode: payload
    }
}

/** Remove the verification code, after the new registered user has successful completed the signup process */
function ClearVerificationCode(state: VerificationState): VerificationState {
    return {
        ...state,
        VerificationCode: undefined
    }
}

/** A new SMS Challenge has started with the specified ID */
function ChallengeStarted(state: VerificationState, challengeId: string): VerificationState {
    return {
        ...state,
        SmsChallengeId: challengeId,
    };
}