import { ConfigValues } from "../Entities/ConfigValues";
import { RouteUrls } from "../../RouteUrls";
import { WellKnownApplicationID } from "../ApplicationID";
import { MgmtBrandId } from "../Entities/Brand";

/** Configuration for the dev environment of our primary brand. */
export const BaseConfig: ConfigValues = {

    ApiBaseUrl: "https://mgmt.mtidev.net/",
    ApiVersionSegments: "api/v1/",
    AppInsightsKey: "0dc824d7-207c-4d4c-9c73-f9bb343bf9f8",
    HomeUrl: "https://www.13cabs.com.au/",
    InternalApplicationId: WellKnownApplicationID.OneThreeCabs,
    IsTestModeSupported: true,
    StaticContentBaseUrl: "https://13orangecdn.azureedge.net/",
    TrackingLinkBaseUrl: "https://13orange2-dev.azurewebsites.net/b/",
    Payment: {
        BrainTreeAuthKey: "production_ktw946tr_sb2td27y6dw23qfp",
        MpsEndpoint: "https://cabcharge-dev.azure-api.net/card-rego-service/site",
        PaymentFaqLink: null
    },
    Auth0: {
        ClientDomain: "13cabsdevtest.au.auth0.com",
        ClientId: "pHQa1jZFqkfGujVV3z4dAmM6DqD9FNXY",
        CustomDomain: "auth-dev.13cabs.com.au",
        Connection: "13CABS-Public-Users"
    },
    WebsiteTitle: "13cabs - we'll get you there",
    BrandColour: "#f58200",
    BrandName: "13cabs",
    BrandContactNumber: "13 2227",
    PrivacyUrl: RouteUrls.PrivacyPolicy,
    DefaultToSilverservice: false,
    GoogleTagManagerKey: "GTM-KNTQ4RQ",
    ExternalContactUsUrl: null,
    DownloadMobileAppPageUrl: "https://www.13cabs.com.au/app/",
    MgmtBrandId: MgmtBrandId.OneThreeCabs,
    DefaultLocation: {
        displayName: "New South Wales",
        geoPoint: { latitude: -33.8473567, longitude: 150.6517881 },
        isGeopointServiced: true,
        isStateServiced: true,
        isValid: true,
        stateCode: "nsw",
    },
    AllowedCountryCodes: ["AU"],
    BookingContactNumber: "13 2227",
    DriverConnectNumber: "1300694752",
    GoogleMapsApiKey: "AIzaSyCESHkkfh1pnvJ_7CGcYUA7U4Z8RWEo3ug"
};