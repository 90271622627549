import React from "react";
import SiteHeader from "../../widgets/Header/SiteHeader";
import HeaderProfilePanel from "../../widgets/Header/HeaderProfilePanel";
import "./ContactUs.scss";
import { SiteFooter } from "../../widgets/site-footer/SiteFooter";
import ContactForm from "./ContactForm";
import {connect} from 'react-redux';
import { ApplicationState } from "../../appState";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { DialogKind } from '../Dialog/DialogEntities';
import { CommonDialogHeaderCore } from "../Dialog/CommonDialogHeader";
import { CommonDialogDescriptionCore } from "../Dialog/CommonDialogDescription";
import { BrandedImage, GetBrandedUrl } from "../Utils/BrandedContentUrls";
import ContactFormV2 from "./ContactFormV2";
import { PreviewFeatureId } from "../Features/FeatureEntities";

interface ContactUsState {
    selectedTab: string;
}

interface ContactUsProps {
    IsMobileDevice: boolean;
    IsContactUsPreviewEnabled: boolean;
}

class ContactUs extends React.Component<ContactUsProps, ContactUsState> {

    constructor(props: ContactUsProps ) {
        super(props);
        this.state={
            selectedTab: 'form'
        }
    }

    setSelectedTab(tab: string) {
        this.setState({selectedTab: tab});
    }

    render() {
        return (
            <div className= "contact-us-page"> 
                    {
                        this.props.IsMobileDevice ? 
                        <>
                            <CommonDialogHeaderCore TopmostDialog={DialogKind.ContactUs} />
                            <CommonDialogDescriptionCore TopmostDialog={DialogKind.ContactUs} />
                        </> : 
                        <div>
                            <SiteHeader />
                            <div className="contact-header">
                            <h1 className="header-title">Contact us</h1>
                            <p className="header-text">The more we know, the more we can help</p>
                            <img src={GetBrandedUrl(BrandedImage.ContactHeroImage)}></img>
                            </div>
                        </div>
                    }
                
                <div className="contact-main-section">
                    {this.props.IsContactUsPreviewEnabled ? <ContactFormV2/> : <ContactForm/>}
                </div>

                <HeaderProfilePanel/>
                <SiteFooter />
            </div>            
        );
    }
}

function mapStateToProps(state: ApplicationState): ContactUsProps {
    return {
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        IsContactUsPreviewEnabled: !!state.features.EnabledPreviews[PreviewFeatureId.ContactUsFormV2]
    };
}

export default connect(mapStateToProps)(ContactUs);