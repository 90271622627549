import { ApiErrorDetail } from "../../Services/ApiContracts";
import { FullErrorId, SystemIds } from "./ErrorEntities";

/** A list of well known error IDs for which we have custom messages or business logic */
export namespace WellKnownErrors {

    /** Payment pre-authorisation failed during booking creation */
    export const PreAuthFailed: FullErrorId = {
        SystemId: SystemIds.BookingAPI,
        ErrorId: 14,
    };

    /** The set of all well known errors. In a list to make it easy to iterate. */
    const AllErrors: FullErrorId[] = [PreAuthFailed];

    /** 
     *  Checks whether the specified API error matches a well known error ID.
     *  Returns the matching record from WellKnownErrors, or null otherwise.
     */
    export function TryMatch(detail: ApiErrorDetail): FullErrorId | null {

        // match at this error
        for (const knownError of AllErrors) {

            if ((knownError.SystemId == detail.SystemId) && (knownError.ErrorId == detail.ErrorId)) {
                return knownError;
            }
        }

        // check any causative error
        if (detail.Cause != null) {
            return TryMatch(detail.Cause);
        }

        // no match
        return null;
    }
}
