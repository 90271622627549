import { WellKnownErrorMessage } from '../Dialog/DialogEntities';
import { GeoPoint } from '../Location/Entities';
import { FullPhoneNumber } from '../PhoneNumber/PhoneNumberEntities';
import { SimpleAccount } from '../User/ProfileEntitiesV2';

/** 
 *  A temporary data type used when building addresses, particularly in the booking template code.
 *  TODO: review for removal.
 */
export interface PlaceResult {
    location: GeoPoint,
    place: google.maps.places.PlaceResult,

    /** 
     *  This is used to preserve the display text from an autocomplete place suggestion.
     *  Only populate it when you legitimately have a value. Otherwise we'll just use the formatted address text of the Google Place.
     */
    ExactDisplayText: string | null;
}

/* Verification ID is no longer supported. Code is what the user types in. */
export interface Verification {
    Code?: string,
}

/** state related to the pickup location serviceability check. We use a type union here because different etra fields are defined in each state. */
export type PickupServiceCheckState = IndeterminateServiceCheck | GoodServiceCheck | BadServiceCheck;

/** Pickup Service Check in the NoInputSelected or CheckInProgress states. No other fields are defined in this state. */
export interface IndeterminateServiceCheck {

    /** Indeterminate: NoInputSelected or CheckInProgress */
    status: ServiceCheckStatus.NoInputSelected | ServiceCheckStatus.CheckInProgress;
}

/** PickupServiceCheckState in the KnownGood case. You must provide SuburbId in this status. */
export interface GoodServiceCheck {

    /** Good: always KnownGood */
    status: ServiceCheckStatus.KnownGood;

    /** Booking API Suburb ID for the pickup location. 
     * Available when status is KnownGood.
     * This is only available and used in BookingControllerV1, not BookingControllerV2.
     */
    suburbId: number | null;

    /** IANA Timezone ID, e.g. "Australia/Sydney". */
    TimeZoneId: string;
}

/** PickupServiceCheckState in the Error or KnownBad case. There is an ErrorMessage is this case. */
export interface BadServiceCheck {

    /** Bad: Error or KnownBad. */
    status: ServiceCheckStatus.Error | ServiceCheckStatus.KnownBad;

    /** Only meaningful / expected to be populated when the status is KnownBad or Error. */
    errorMessage: WellKnownErrorMessage;

    /** This state stands for if the red line error message display or not under addresses in booking form */
    isPickupErrorMessageShown: boolean;

    /** This field is used for internal appInsights purpose */
    internalErrorMessage: string;
}

/** The result of checking the serviceability of the input address, but also intermediate states like "in progress". */
export enum ServiceCheckStatus {

    /** The input address has not been selected yet. This is not an error state per se. */
    NoInputSelected = 'No Input Selected',
    
    /** A check is in flight (API call pending) */
    CheckInProgress = 'Check In Progress',

    /** The input address was checked and found to be valid. */
    KnownGood = 'Known Good',

    /** The input address was checked and found to be invalid. */
    KnownBad = 'Known Bad',

    /** An error occurred while checking the status. The value is not known. */
    Error = 'Error',
}

/** Account specific properties to create booking with an account. */
export interface AccountBookingPayload {
    SelectedAccount: SimpleAccount;
    OrderNumber?: string;    
    FileNumber?: string;
    SelectedAccountIndex: number;
}

/** Input type to the PickupServiceCheckUpdate redux action. */
export interface PickupServiceabilityDetails {
    ServiceabilityCheckState: PickupServiceCheckState;
    PickupPlaceId: string;
}

/** Contact details for a location in the booking (widget). */
export interface BookingLocationContact {
    Name?: string;

    /** pickup/dropoff contact phone number. */
    Phone: FullPhoneNumber | null;
}

/** A booking location e.g. Pickup vs Dropoff. May turn into an array index later. */
export enum LocationIndex {

    Pickup = "Pickup",

    Dropoff = "Dropoff",
}