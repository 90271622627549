
/**
 * What caused the phone verification UI to show: booking creation or signup.
 * Previously, verification was only used in 2 scenarios:
 *     1) Guest booking;
 *     2) Logged-in users whose contact number is mobile, but this number is not verified yet.
 * Both scenarios are relating to booking.
 * 
 * After introducing the new Auth0 interface, we will do mobile verification before signup.
 * This means, for re-usable purpose, component ../modules/Verification/Verification needs to know "who" triggered it, then it will know:
 *     1) Where to read data;
 *     2) What to do after verification finished.
 */
export enum VerificationTrigger {
    Booking = "Booking",
    Signup = "Signup"
}

/**
 * This is a contact number object/placeholder that stores user's contact number related information, which is used for verification purpose;
 * Only mobile can be used for verification.
 */
export interface UserContactNumberInfo {

    /** This doesn't include the country dial code prefix */
    Contactnumber?: string;
    CountryInfo?: CountryInfo;

    /** Error Message about the contact number displayed on the Booking widget */
    ErrorMessage?: string
}

/** This is a asistant type for actions & actionCreators restriction */
export interface CountryInfo {

    /** Numeric prefix for the country, e.g. "+61". Should always have a leading +. */
    CountryCode: string;

    /** Full name of the country. For app insights diagnostics only. */
    CountryName?: string;

    /** Two letter code, e.g. "au".  */
    CountryIsoCode?: string;
}

/**
 * This represents a phone number object which mostly used for parsing a number with any format
 */
export interface ContactNumberObject {

    /** Local portion of the phone number, not including the country prefix. */
    NumberPart: string;

    /** Numeric country prefix with leading "+". */
    CountryCode: string;

    /** A two-letter country code like 'au' or 'uk' */
    IsoRegionCode: string;
}

/**
 * This represents whether a contact number is "mobile" or "landline".
 */
export enum ContactNumberKind {
    Mobile = "Mobile",
    Landline = "Landline",
}

export enum ContactNumberValidateOutcome {

    /** The mobile number verification succeeded */
    ValidMobileNumber,

    /** The landline number verification succeeded */
    ValidLandlineNumber,

    /** The phone number provided is neither a valid mobile nor a valid landline number */
    InvalidPhoneNumber,

    /** Error occured while verifying the mobile number */
    ErrorFound,

    /** Maximum verification limit reached */
    ExceededMaxNumberOfAttempts,

    /** The mobile number verification failed */
    VerificationFailed
}
