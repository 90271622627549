import React from 'react';
import { connect } from 'react-redux';
import './Dialog.scss';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { DialogKind } from './DialogEntities';
import { ApplicationState } from '../../appState';
import { GetBrandedUrl, BrandedImage } from '../Utils/BrandedContentUrls';
import appstore from '../../appStore';
import { DialogConfigLookup } from "./DialogConfig";
import { Dispatch } from "../Dispatch";
import { Tooltip } from '@mui/material';

interface PropsFromStore {
    openDialogs: Partial<Record<DialogKind, boolean>>;
    topmostDialog: DialogKind | null;
}

/** Close button for popups. */
class DialogClose extends React.Component<PropsFromStore> {

    render() {
        /**
         * Normally, DialogConfig.RequireCloseButton decide whether this component to show or not.
         * There are some exeptions, such as LocationChooser, they do not have an entry in DialogConfig, but still need X button.
         */
        const isForceToShow = this.props.openDialogs[DialogKind.LocationChooser] || this.props.openDialogs[DialogKind.RetryErrorMessaging];
        const config = DialogConfigLookup(this.props.topmostDialog!);
        if (!config?.RequireCloseButton && !isForceToShow) return null;

        const closeButton = this.props.openDialogs[DialogKind.LocationChooser] ? GetBrandedUrl(BrandedImage.CloseIcon) : getContentUrl(ContentURL.images.BlackX);
        const closeButtonStyle = this.props.openDialogs[DialogKind.RetryErrorMessaging] ? "dialog-header dialog-header-position-for-small-panel" : "dialog-header dialog-header-default-position";
        
        return (
            <div className={closeButtonStyle}>
                <Tooltip title="Close" arrow>
                    <img alt="close" src={closeButton} onClick={() => config?.CustomCloseBehaviour ? config.CustomCloseBehaviour() : this.closeDialog()} /> 
                </Tooltip>               
            </div>
        );
    }

    closeDialog() {
        Dispatch.Dialog.CloseTopDialog();

        const closer = appstore.getState().dialog2.CloseDialogCallback;
        if (closer) closer();
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        openDialogs: state.dialog.openDialogs,
        topmostDialog: state.dialog.topmostDialog
    };
}

export default connect(mapStateToProps)(DialogClose);