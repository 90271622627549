import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {Link} from "react-router-dom";
import "./Header.scss";
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import { LocationDisplayPanel } from '../location/display-panel/LocationDisplayPanel';
import HeaderLoginRegister from "./HeaderLoginRegister";
import { connect } from 'react-redux';
import { Dialog } from '../../modules/Dialog/Dialog';
import { ApplicationState } from '../../appState';
import { LocationData } from '../../modules/Location/Entities';
import { Dispatch } from '../../modules/Dispatch';
import { MapView } from '../NavBar/TabEntities';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { DialogWrapper } from '../../modules/Dialog2/UI/DialogWrapper';
import { GetValues } from '../../Config/MyAppConfig';
import FeatureList from '../../modules/WhatsNew/FeatureList';
import { UILayoutMode } from '../../modules/UILogicControl/UILogicControlEntities';
import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';
import { HeaderMenuItem } from './HeaderEntities';
import { GetMenuItems } from "./GetMenuItems";
import { LoginStatusKind } from "../../modules/Authentication/AuthEntities";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { ParseWashProfileName } from "../../modules/Authentication/ProcessUserProfile";
import CredentialsController from "../../modules/Authentication/Login/CredentialsController";
import { DialogKind } from '../../modules/Dialog/DialogEntities';
import { LogEvent } from '../../utils/LogEvent';
import { Badge } from '@mui/material';

interface PropsFromStore {
    UnderMobileMode: boolean;
    preferredLocation: LocationData;
    LoginStatus: LoginStatusKind;
    UserProfile: SimpleUserProfile | undefined;
    NewFeatureList: number[];
}

interface HeaderState {
    isMenuOpen: boolean;
}

/**
 * Toggle function of burger icon (this burger only used for tablet device for now) to decide whether menu is showing or not.
 */
interface ToggleMenuProps {
    ToggleMenu: () => void;
}

// RouteComponentProps is needed for withRouter to work with typescript.
class SiteHeader extends React.Component<RouteComponentProps & PropsFromStore, HeaderState> {
    constructor(props: RouteComponentProps & PropsFromStore) {
        super(props);

        this.state = {
            isMenuOpen: false,
        }
    }

    toggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }

    /** On click of 'Book a taxi' menu item, change the tab to be Map View always. 
     * This behaviour is to go to the map view if clicked on Book a taxi while in other tabs in the booking page. */
    setMapView() {
        Dispatch.Tab.SelectItem(MapView);
    }

    /** Returns the menu item with the suitable link type (i.e. <Link> or <a>). */
    generateMenuItem(item: HeaderMenuItem, index: number): JSX.Element {
        if (item.IsExternalLink) {
            return (
                <a href={item.ToLink} target="_blank" key={index} rel="noopener"><span>{item.Title}</span></a>
            );
        } else {
            return (
                <Link to={item.ToLink} key={index} onClick={item.ToLink === "/booking" ? this.setMapView : undefined} >
                    {this.props.location.pathname === item.ToLink
                        ? <span className="active-item">{item.Title}</span>
                        : <span>{item.Title}</span>}
                </Link>
            );
        }
    }

    render() {

        const menuClass = this.state.isMenuOpen ? "nav-links responsive" : "nav-links";

        /** Display download app button for 13cabs desktop site only */
        const showDownloadAppButton = FeatureFlags.ShowDownloadMobileAppButton && !this.props.UnderMobileMode && !this.state.isMenuOpen;

        let brandLogoElement = <img src={GetBrandedUrl(BrandedImage.Logo)} alt="logo" className="logo" />;

        const homeUrl = this.GetFullHomeUrl();

        if (homeUrl) {
            brandLogoElement = (<a href={homeUrl} target="_blank">
                <img src={GetBrandedUrl(BrandedImage.Logo)} alt="logo" className="logo" />
            </a>);
        }

        // Move the main menu to left when Login/signup buttons are hidden.
        const mainMenuClass = FeatureFlags.GuestOnly ? "header-right-side-pannel header-menu-left" : "header-right-side-pannel";

        return (
            <header>
                <div id="app-header" className="header-content">
                    {brandLogoElement}

                    { 
                        !FeatureFlags.FareEstimatorTool && !this.props.UnderMobileMode && < LocationDisplayPanel /> 
                    }
                    
                    {
                        FeatureFlags.FareEstimatorTool &&  showDownloadAppButton && <a className="btn-download-app" target="_blank" href={GetValues().DownloadMobileAppPageUrl} onClick={() => LogEvent.OnDownloadMobileAppButtonClicked()}>Download the 13cabs app</a>
                    }

                    <div className={mainMenuClass}>
                        <div className={menuClass}>
                            {FeatureFlags.UserFlagsNavLink && (
                                <Link to="/flags" key={100}>
                                    <span>Features</span>
                                </Link>
                            )}

                            {
                                !FeatureFlags.FareEstimatorTool && showDownloadAppButton && <a className="btn-download-app" target="_blank" href={GetValues().DownloadMobileAppPageUrl} onClick={() => LogEvent.OnDownloadMobileAppButtonClicked()}>Download the 13cabs app</a>
                            }

                            {homeUrl && 
                                <a href={homeUrl} target="_blank">
                                    <span>Home</span>
                                </a>
                            }
                            {GetMenuItems().map((item, index) => this.generateMenuItem(item, index))}
                        </div>

                        <TabletBurger {...this.props} ToggleMenu={() => this.toggleMenu()} />
                        {!FeatureFlags.GuestOnly && <HeaderWords {...this.props} />}
                        <HeaderPortrait {...this.props} />
                        {!FeatureFlags.GuestOnly && <HeaderLoginRegister />}
                    </div>                 
                </div>
                { !this.props.UnderMobileMode && <Dialog /> }
                <DialogWrapper />
                { !this.props.UnderMobileMode && FeatureFlags.WhatsNew && <FeatureList /> }
            </header>
        );
    }

    /** The URL of the external website from the "Home" link or clicking on the brand icon. */
    GetFullHomeUrl(): string | null {

        // the primary URL
        const homeUrl = GetValues().HomeUrl;
        if (homeUrl == null) return null;

        // if available, include a location indicator
        const location = this.props.preferredLocation;

        if (location.isKnown && location.value.displayName) {
            return `${homeUrl}?location=${location.value.displayName}`;
        }
        else {
            return homeUrl;
        }
    }
}

function mapStateToProps(state: ApplicationState) : PropsFromStore {
    return {
        preferredLocation: state.location.preferredLocation,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        LoginStatus: state.authentication.LoginStatus,
        UserProfile: state.authentication.UserProfile,
        NewFeatureList: state.features.NewFeatureList
    };
}

export default connect(mapStateToProps)(withRouter(SiteHeader));

/**
 * Burger only used for tablet devicefor now
 */
const TabletBurger: React.FC<PropsFromStore & ToggleMenuProps> = (props) => {

    if (props.UnderMobileMode) return null;

    return (
        <div className="icon" onClick={props.ToggleMenu}>
            <img src={getContentUrl(ContentURL.images.headerAndNav.BurgerMenu)} width="20"></img>
        </div>
    );
}

/**
 * Header portrait
 */
const HeaderPortrait: React.FC<PropsFromStore> = (props) => {

    if (!props.UnderMobileMode) return null;

    if (props.LoginStatus === LoginStatusKind.GetUserProfileInProgress) {
        return (
            <div className="header-loading">
                <img src={getContentUrl(ContentURL.images.Loading)} width="50"></img>
            </div>
        );
    }

    const menuIcon = getContentUrl(ContentURL.images.headerAndNav.BurgerMenu);
    const unviewedFeatureCount = props.NewFeatureList.length;

    return (
        <div className="header-portrait" onClick={() => Dispatch.UILogicControl.OpenMenu()}>
            <Badge badgeContent={unviewedFeatureCount} color="error">
                <img src={menuIcon} width="23"></img>
            </Badge>
        </div>
    );
}

/**
 * Header words, such as "Log in" or "Hi someone"
 */
const HeaderWords: React.FC<PropsFromStore> = (props) => {

    if (!props.UnderMobileMode) return null;

    if (!props.UserProfile) {
        return (
            <div className="header-words-section" onClick={ClickLoginButton}>
                <span>Log in</span>
            </div>
        );
    }

    const name = "Hi " + ParseWashProfileName(props.UserProfile.DisplayName).FirstName;

    return (
        <div className="header-words-section" onClick={() => Dispatch.UILogicControl.OpenMenu()}>
            <span>{name}</span>
        </div>
    );  
}

/** Login behaviour */
export function ClickLoginButton() {

    if (FeatureFlags.MultiTenantLogin) {
        Dispatch.Dialog.ShowDialog(DialogKind.MultiTenantSelector);
        return;
    }

    new CredentialsController().DoLogin(); 
}

