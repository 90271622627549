import {  UserProfileNavBarNames } from "./UserProfileNavEntities"
import { ContentURL } from '../Utils/ContentURL';
import { FeatureFlags } from "../../Config/FeatureFlags";

/**
 * This file holds all the individual and the array for User profile  nav items
 */


/**
 * My Personal Detail Menu Item
 */
export const personalDetailsNavItem = { Title: 'My Personal Details', Name: UserProfileNavBarNames.PersonalDetails, Image: ContentURL.images.UserProfile.MyProfileIcon };
/**
 * My Wallet Detail Menu Item
 */
export const walletNavItem = { Title: 'My Wallet', Name: UserProfileNavBarNames.Wallet, Image: ContentURL.images.PaymentType.WalletIconBlack };

/**
 * Address Points Menu Item
 */
export const addressPointsNavItem = { Title: "My Saved Addresses", Name: UserProfileNavBarNames.AddressPoints, Image: ContentURL.images.UserProfile.Star };

/**
 * Available Features Menu Item
 */
 export const availableFeaturesNavItem = { Title: "Available Features", Name: UserProfileNavBarNames.AvailableFeatures, Image: ContentURL.images.UserProfile.Rocket};

/**
 * All Nav Items 
 */
export let allUserProfileNavItems = FeatureFlags.CardNotPresentPayment ? [ walletNavItem] : [];

/** Add My Personal Details, only when the flag is set to true
 * Using unshift as MyPersonalDetails should be the first item in the menu
 */
if(FeatureFlags.ShowMyPersonalDetailsUserProfile)
    allUserProfileNavItems.unshift(personalDetailsNavItem);

if (FeatureFlags.AddressPoints) allUserProfileNavItems.push(addressPointsNavItem);

if (FeatureFlags.PreviewFeatures) allUserProfileNavItems.push(availableFeaturesNavItem);
