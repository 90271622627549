import { ApplicationState } from "../../appState";
import { WalletPaymentOptions, PayDriverOption, PaymentCardErrorType, PaymentOption, PaymentOptionKind, SatssVoucherOption } from "./PaymentEntities";
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";
import { PaymentCard } from "../../Services/PaymentEntities";
import { Dispatch } from "../Dispatch";
import appstore from "../../appStore";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { BookingWorkFlowState } from "../Booking/Redux/BookingState";
import { ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { FeatureFlags } from "../../Config/FeatureFlags";

/** Decides whether to include payment method (card) details in the booking creation request based on the selected payment method.
 * E.g.: No need to send the payment method to the backend if the user selected TSS or Cabcharge (voucher, digital pass etc). Those options are there only to inform the user and dispatch system's perspective, they are all 'Cash/EFTPOS'.
 */
export function ShouldIncludeCardIdInBooking(booking: BookingWorkFlowState) {
    if (!booking.PaymentOption) return false;
    if (!booking.PaymentOption.Card?.CardId) return false;
    if (booking.PaymentOption.Kind !== PaymentOptionKind.Card) return false;
    if (booking.AccountData) return false;

    return true;
}

/** Creates a payment option with the provided card */
export function ConvertToPaymentOption(paymentCard: PaymentCard): PaymentOption {
    
    const paymentOption: PaymentOption = {
        Card: paymentCard,
        Id: paymentCard.CardId,
        Type: paymentCard.CardType,
        Name: paymentCard.CardName,
        Kind: PaymentOptionKind.Card,
        IsDefault: paymentCard.IsDefault
    };

    return paymentOption;
}

/**
 * PaymentOptions will be populated with the following: List of payment cards, PayDriverDirectly and SATSS.
 * If none of the payment card is selected as the default option, then make the "Paying the Driver directly" as the default payment option, inorder to show it as the default payment method on the wallet list.
 */
export function PopulatePaymentOptions(state: ApplicationState): PaymentOption[] {
    
    const paymentCardList = state.payment.AllCards;

    // Add user payment cards
    const paymentOptions = paymentCardList.map(ConvertToPaymentOption);

    const defaultCard = paymentCardList.some(card => card.IsDefault === true);

    // Add "Paying the Driver directly" option
    paymentOptions.push({...PayDriverOption, IsDefault: !defaultCard});

    // Add "SATSS" option for South Australain location
    if (IsSatssOptionAvailable(state)) paymentOptions.push(SatssVoucherOption);
    
    return paymentOptions;
}

/**
 * Deselect the "SATSS" payment option if the user has changed their location to a non-SA region.
 */
export function ConsiderSatssInvalidation() {

    const state = appstore.getState();

    // still valid
    if (IsSatssOptionAvailable(state)) return;

    // not selected in the first place
    if (state.booking.PaymentOption?.Kind !== PaymentOptionKind.SatssVoucher) return;

    Dispatch.Booking.PaymentMethod(PayDriverOption);

    // Do not show payment method error for Booking template
    if (state.uiLogicControl.BookingForm.BookingWidgetMode === BookingWidgetModeKind.Booking) {
        Dispatch.Payment.SetError(PaymentCardErrorType.CardNotSelected);
    }
}

/** Decides whether to add SATSS option in to the dropdown based on the current address. */
function IsSatssOptionAvailable(state: ApplicationState) {
    
    const { preferredLocation } = state.location;

    if (ShouldPromptUserToAddCard(state)) return false;

    // Not a valid location. No need to proceed.
    if (!preferredLocation.isKnown) return false;

    // Current selected Location is not SA
    if (preferredLocation.value.stateCode !== 'sa') return false;

    // SATSS is not supported for templates because we can't save this option on the template
    if (state.uiLogicControl.BookingForm.BookingWidgetMode !== BookingWidgetModeKind.Booking) return false;

    return true;
}

/** Checks whether the specified payment option is allowed to be displayed on the my wallet list */
export function FilterAllowedPaymentOptions(option: PaymentOption): PaymentOptionKind | undefined {
    return WalletPaymentOptions.find(x => x === option.Kind);
}

/** Prompt guest users to add card, when the price guarantee toggle is ON */
export function ShouldPromptUserToAddCard(state: ApplicationState) {

    if (state.authentication.LoginStatus !== LoginStatusKind.LoggedOut) return false;
    
    if (IsPaymentAndServiceValidForPriceGuarantee(state)) return false;

    return true;
}

/** Check if Price Guarantee is valid against the selected service and payment method. */
export function IsPaymentAndServiceValidForPriceGuarantee(state: ApplicationState): boolean {

    if (!state.condition.IsPriceGuaranteeSelected) return true;

    // Can do Parcel bookings with cash option
    if (state.condition.SelectedCondition.Service.kind === ServiceKind.Parcel && FeatureFlags.ParcelFixedFareWithCash) return true;

    // Price Guarantee is allowed only for CNP bookings.
    if (state.booking.PaymentOption?.Kind === PaymentOptionKind.Card) return true;

    return false;
}