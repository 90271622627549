import { Api } from "../../Services/Api";
import { CheckAddPlus } from "../../utils/Formattingutil";
import { Dispatch } from "../Dispatch";
import { SetErrorMessages } from "../Utils/CommonHelpers";

/**
 * Start an SMS Verification Challenge.
 * An SMS will be sent to the specified phone number.
 * The ChallengeId coming from the API will be stored in Redux for subsequent API calls.
 */
export async function StartSmsVerification(phoneNumber: string): Promise<StartSmsResult> {

    // known issue workaround: number sometimes missing "+" (e.164 format)
    phoneNumber = CheckAddPlus(phoneNumber);

    // step 1: phone number check
    const numberTestResult = await Api.SmsVerification.TestPhoneNumber(phoneNumber);

    if (!numberTestResult.isSuccess) {

        const message = SetErrorMessages(numberTestResult).errorMessage;

        const failure: StartSmsFailure = {
            Outcome: StartSmsOutcome.InvalidPhoneNumber,
            ErrorMessage: message,
        };

        return failure;
    }

    // step 2: actual start
    const startResult = await Api.SmsVerification.StartChallenge(phoneNumber);

    if (!startResult.isSuccess) {

        const message = SetErrorMessages(startResult).errorMessage;

        const failure: StartSmsFailure = {
            Outcome: StartSmsOutcome.InvalidPhoneNumber,
            ErrorMessage: message,
        };

        return failure;
    }

    // success
    const challengeId = startResult.value;
    Dispatch.Verification.ChallengeStarted(challengeId);

    const success: StartSmsSuccess = {
        Outcome: StartSmsOutcome.Success,
        ChallengeId: challengeId,
    };

    return success;
}

/** 
 *  The outcome of the Start SMS Verification operation.
 *  Success with a ChallengeId or Failure with an error message.
 */
type StartSmsResult = StartSmsSuccess | StartSmsFailure;

/** A successful SMS Verification Start. */
interface StartSmsSuccess {
    Outcome: StartSmsOutcome.Success;

    /** Challenge ID from the API. Used for follow-on API calls. */
    ChallengeId: string;
}

/** A failure to start an SMS Verification */
interface StartSmsFailure {
    Outcome: StartSmsOutcome.InvalidPhoneNumber | StartSmsOutcome.OtherError;

    ErrorMessage: string;
}

/** The different outcomes of the Start SMS Verification operation that can drive business logic decisions. */
export enum StartSmsOutcome {
    Success = 1,

    /** This specific error gets special attention because it requires the user to fix. */
    InvalidPhoneNumber = 2,

    /** More generic errors here (could be anything) */
    OtherError = 3,
}