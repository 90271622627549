import { PhoneNumber, PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from "google-libphonenumber";
import { GetValues } from "../../Config/MyAppConfig";
import { CheckAddPlus } from "../../utils/Formattingutil";
import { CustomErrorMessages } from "../Utils/ErrorMessages";
import { FullPhoneNumber, PhoneCheckResult, PhoneError } from "./PhoneNumberEntities";

/** Phone validation methods */
export namespace CheckPhone {

    /** Singleton of Google's parsing library helper class */
    const phoneUtil = PhoneNumberUtil.getInstance();

    /** Standard return value for most error cases */
    const genericError: PhoneError = {
        IsValid: false,
        Error: CustomErrorMessages.InvalidPhoneNumber
    };

    /**
     * Check a phone number represented as a local number with an associated country (2 letter code)
     */
    export function FromParts(countryIso2: string, localNumber: string): PhoneCheckResult {

        try {
            const phoneNumber = phoneUtil.parse(localNumber, countryIso2);
            return FromParsedNumber(phoneNumber);
        }
        catch (ex) {
            return genericError;
        }
    }

    /**
     * This is a phone number that we're not certain about the format of.
     * It may be an international number or it might be the local part only.
     */
    export function FromRawNumber(number: string) {

        // try as an international number
        try {
            const phoneNumber = phoneUtil.parse(CheckAddPlus(number));
            return FromParsedNumber(phoneNumber);
        }
        catch (ex) { }

        // try as a local number
        const bestGuessCountry = GetValues().AllowedCountryCodes[0];

        try {
            const phoneNumber = phoneUtil.parse(number, bestGuessCountry);
            return FromParsedNumber(phoneNumber);
        }
        catch (ex) { }

        // give up
        return genericError;
    }

    /**
     * The input is a number that has been successfully parsed.
     */
    function FromParsedNumber(number: PhoneNumber): PhoneCheckResult {

        if (!phoneUtil.isValidNumber(number)) {
            return genericError;
        }

        const phoneType = phoneUtil.getNumberType(number);
        const isMobile = (phoneType === PhoneNumberType.MOBILE) || (phoneType === PhoneNumberType.FIXED_LINE_OR_MOBILE);

        const countryIso2 = phoneUtil.getRegionCodeForNumber(number);
        const fullNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
        const localNumber = number.getNationalNumber();

        // I don't really understand how countryIso2 and localNumber can return undefined values.
        // I assume they won't be undefined since the PhoneNumber object has been built from parsing.
        const fullPhone: FullPhoneNumber = {
            CountryCodeIso2: countryIso2!,
            FullNumber: fullNumber,
            IsMobile: isMobile,
            LocalPart: localNumber!.toString(),
        };

        return {
            IsValid: true,
            Value: fullPhone,
        };
    }
}