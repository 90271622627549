import appstore from "../../../appStore";
import { DialogKind } from "../../Dialog/DialogEntities";
import { Dispatch } from "../../Dispatch";
import { BookingTabKind, UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { NewFeatureIds } from "../../WhatsNew/FeaturesEntities";

/** This function will be call from LoginSession.tsx
 * if latest feature is new to the user, show popup dialog in Mobile view.
*/
export function ConsiderShowingNewFeaturePopup() {
    const appState = appstore.getState();
    const newKeys =  NewFeatureIds;
    const newFeatures = newKeys.filter(k => !appState.features.AcknowledgedFeatureList.includes(k)); 
    
    if (newFeatures.length === 0) return;

    if (appState.uiLogicControl.BookingForm.SelectedBookingTab !== BookingTabKind.NewBooking ) return;

    if (appState.uiLogicControl.LayoutMode === UILayoutMode.Desktop) return;

    Dispatch.Dialog.ShowDialog(DialogKind.NewFeature);

    Dispatch.UILogicControl.ShowMobileHeader();
}