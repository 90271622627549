import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { PaymentState, PaymentStateInitialState } from "./PaymentState";
import { PaymentCard } from "../../Services/PaymentEntities";
import { PaymentCardErrorType } from "./PaymentEntities";

/** Redux store slice for payments. */
const slice = new ReduxStoreSlice<PaymentState>("Payment", PaymentStateInitialState);

/** Dispatcher for the Payment store slice. */
export const PaymentDispatchV2 = {
    DataRefresh: slice.Action("Data Refresh", DataRefresh),
    SetCardToRemove: slice.Action("Set Card To Remove", SetPaymentCardToRemove),
    RemoveCard: slice.Action("Remove Payment Card", RemovePaymentCard),
    SetDefaultCard: slice.Action("Set Default Payment Card", SetDefaultPaymentCard),
    ClearDefaultCard: slice.EmptyAction("Clear Default", ClearDefaultCard),
    ToggleCardRegistrationPanel: slice.Action("Toggle Card Registration Panel", ToggleCardRegistrationPanel),
    SetError: slice.Action("Set Payment Card Error", SetCardErrorMessage),
    ShowAddPaymentCardScreenAfterSignup: slice.EmptyAction("Show Add Payment Card", ShowAddPaymentCardScreenAfterSignup),
    HideAddPaymentCardScreenAfterSignup: slice.EmptyAction("Hide Add Payment Card", HideAddPaymentCardScreenAfterSignup)
};

/** Reducer for the Payment store slice. */
export const PaymentReducerV2 = slice.MakeCombinedReducer();

/** Completely refresh the list of cards data. This can update the Default card too. */
function DataRefresh(state: PaymentState, allCards: PaymentCard[]): PaymentState {
    const newDefault = allCards.find(i => i.IsDefault) ?? null;

    return {
        ...state,
        AllCards: allCards,
        DefaultCard: newDefault,
    };
}

/** set card to be removed. This value is used by remove payment dialog. */
function SetPaymentCardToRemove(state: PaymentState, card: PaymentCard): PaymentState {
    return {
        ...state,
        CardToRemove: card
    };
}

/** Removed the selected card. This will affect the All list, and possibly the default card will become null.  */
function RemovePaymentCard(state: PaymentState, card: PaymentCard): PaymentState {
    const newCardList = state.AllCards.filter((paymentCard) => {
        return paymentCard.CardId !== card.CardId;
    });

    // reset default if this was it
    const newDefault = state.DefaultCard?.CardId === card.CardId ? null : state.DefaultCard;

    return {
        ...state,
        AllCards: newCardList,
        DefaultCard: newDefault,
    };
}

/** The specified card has been set as the default. Reflect it in the state. 
 *  This includes updating the list of all cards. */
function SetDefaultPaymentCard(state: PaymentState, newDefault: PaymentCard): PaymentState {

    const newCards = state.AllCards.map((card) => {

        // replace with default-ised version
        if (card.CardId == newDefault.CardId) return newDefault;

        // clear other defaults
        if (card.IsDefault) return {
            ...card,
            IsDefault: false,
        };

        // unchanged
        return card;
    });

    return {
        ...state,
        AllCards: newCards,
        DefaultCard: newDefault
    };
}

/**
 * Remove any existing default card. This can change the list of all cards.
 */
function ClearDefaultCard(state: PaymentState): PaymentState {

    const newCards = state.AllCards.map((card) => {

        // clear defaults
        if (card.IsDefault) return {
            ...card,
            IsDefault: false,
        };

        // unchanged
        return card;
    });

    return {
        ...state,
        AllCards: newCards,
        DefaultCard: null,
    };
}

/** Toggle card registration panel. */
function ToggleCardRegistrationPanel(state: PaymentState, cardRegistrationPanelActive: boolean): PaymentState {
    return {
        ...state,
        IsCardRegistrationPanelOpen: cardRegistrationPanelActive
    };
}

/** Set the error message if the selected payment card is invalid (e.g: card is expired or removed from the account)*/
function SetCardErrorMessage(state: PaymentState, errorMessage: PaymentCardErrorType | null): PaymentState {
    return {
        ...state,
        PaymentCardError: errorMessage
    }
}

/** Show the add payment card screen after signup to the logged-in user */
function ShowAddPaymentCardScreenAfterSignup(state: PaymentState) : PaymentState {
    return {
        ...state, 
        ShouldDisplayAddPaymentCardScreenAfterSignup : true
    }
}

/** Hide the add payment card screen */
function HideAddPaymentCardScreenAfterSignup(state: PaymentState) : PaymentState {
    return {
        ...state,
        ShouldDisplayAddPaymentCardScreenAfterSignup : false
    }
}