import { Api } from "../../Services/Api";
import { PaymentCard } from "../../Services/PaymentEntities";
import { Dispatch } from "../Dispatch";
import { SetErrorMessages } from "../Utils/CommonHelpers";
import braintree from "braintree-web";
import appstore from "../../appStore";
import { MpsPaymentCard, PaymentOption, PaymentOptionKind } from "./PaymentEntities";
import { GetValues, GetMyBrand } from "../../Config/MyAppConfig";
import { FeatureFlags } from "../../Config/FeatureFlags";

/** Helper function to get the list of cards associated with the logged in user. */
export async function LoadMyCards() {
    
    if (!FeatureFlags.CardNotPresentPayment) return;

    const serviceResult = await Api.Payment.GetCards();    
    if (!serviceResult.isSuccess) return;    
    
    // Save payment cards
    const cardList = serviceResult.value;
    Dispatch.Payment.DataRefresh(cardList);
}

/** An optimised version of LoadMyCards, for when the user has just signed up, so you know they have no cards. */
export function LoadEmptyListOfCards() {

    const cardList: PaymentCard[] = [];
    Dispatch.Payment.DataRefresh(cardList);
}

/**
 * 1. Sort cards, so the default payment method is on top
 * 2. Cash should always be at the bottom unless, it is a default payment method
 */
export function SortPaymentOptions(paymentOptions: PaymentOption[]): PaymentOption[] {

    paymentOptions.sort((x,y) => {
        
        if ((!y.IsDefault) && y.Kind === PaymentOptionKind.PayDriverDirectly) return -1;

        return Number(y.IsDefault) - Number(x.IsDefault);        
    });

    return paymentOptions;
}

/** 
 * This gets a user's MPS profile , an MPS profile is different than a 13cabs user profile
 * Booking api takes care of creating an mps user if it doesn't exists.
 * We don't use  the value returned by this function(maybe in future), it is used to just ensure than profile exists on MPS(which is taken care by booking api)
 * As a side note :- 
 * Booking API logic for getMpsprofile
 * 1) get user profile for MPS
 * 2) if it doesn't exists create a new user in MPS
 * Note:- when a user is first created, MPS returns null for custId in response
 */
export async function GetUserMpsProfile(isNewSignUp: boolean) {

    if (!FeatureFlags.CardNotPresentPayment) return;

    if (!isNewSignUp && UserIsNotValidForMps()) return;

    const serviceResult = await Api.Payment.GetMpsProfile();
    let result;
    if (serviceResult.isSuccess) {
        result = serviceResult.value
    } else {
        result = SetErrorMessages(serviceResult);
    }
    return result;
}

/** 
 *  Returns true if the current user account is certain to not be supported by MPS.
 *  MPS requires a valid email, which we check from the User profile.
 *  Since the email field is a bit unreliable, we'll also check that the user has accounts, which is a known cause of the underlying issue (manually created users via CRM for Account clients.)
 */
function UserIsNotValidForMps(): boolean {

    // this method is only called in the post-login flow
    const profile = appstore.getState().authentication.UserProfile!;

    const hasDodgyEmail = profile.Email.indexOf('@') == -1;
    const hasAccounts = profile.Accounts.length > 0;

    return hasDodgyEmail && hasAccounts;
}

/**
 * set the device data field, it uses braintree library to get device data
 */
export async function SetDeviceData() {
    var braintreeClient = await braintree.client.create({ authorization: GetValues().Payment!.BrainTreeAuthKey });
    var dataCollector = await braintree.dataCollector.create({ client: braintreeClient, kount: true, paypal: true });
    var deviceData = dataCollector.deviceData;

    Dispatch.Booking.SetDeviceData(deviceData);
}

/**
 * This helper function will call the service to set a new default card(credit/cabcharge) and calls dispatch to trigger change in card list state
 * a credit/cabcharge card is set as default
 */
export async function SetCardAsDefaultPayment(newDefaultCard: PaymentCard) {

    // copy of new default card,change isdefault to true and pass to EditCard service
    let newDefaultCardCopy = Object.assign({}, newDefaultCard);
    newDefaultCardCopy.IsDefault = true;

    const serviceResponse = await Api.Payment.EditCard(newDefaultCardCopy);

    if (serviceResponse.isSuccess) {
        Dispatch.Payment.SetDefaultCard(newDefaultCardCopy);
    }
}

/**
 * This function handles unsetting of default card or setting cash as default
 * A credit/cabcharge card is unset(toggle off), in this case cash becomes a default
 * Cash is set as default, in this case previous default card needs to be passed to edit card service
 * Cash is unset(toggle off), in this case nothing should happen, because a cash can't be toggle off from default
 */
export async function SetCashAsDefaultPayment(prevDefaultCard: PaymentCard | null) {

    //cash is unset
    if (!prevDefaultCard) return;

    // copy of prev default card, change isdefault to false and pass to EditCard service
    let prevDefCardCopy = Object.assign({}, prevDefaultCard);
    prevDefCardCopy.IsDefault = false;
    
    const serviceResponse = await Api.Payment.EditCard(prevDefCardCopy);
    if (serviceResponse.isSuccess) {

        Dispatch.Payment.ClearDefaultCard();
    }
}

/**
 * Get src for Card Registration Iframe
 */
export function GetCardRegistrationIFrameRequest() {
    const CardRegURL = `${GetValues().Payment!.MpsEndpoint}/iframe`;
    const CardRegRequest = `${CardRegURL}?jwt=${appstore.getState().authentication.AuthToken!.idToken}&theme=${GetMyBrand()}`
    return CardRegRequest;
}

/** 
 * This creates a PaymentCard object from MpsPaymentCard
 */
export function createPaymentCardObj(cardObj: MpsPaymentCard): PaymentCard {
    return {
        CardId: cardObj.CardId,
        CardName: cardObj.CardName,
        CardNumber: cardObj.CardNumber,
        CardType: cardObj.CardType,
        CardExpiry: cardObj.CardExpiry,
        IsDefault: false
    };
}