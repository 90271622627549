
import { getContentUrl, ContentURL }  from '../../modules/Utils/ContentURL';

/** Get the image URL for the provided image name.*/
function getVehicleImage(imageName: string) {
    return getContentUrl(ContentURL.images.vehicles[imageName]);
}

export enum ServiceKind {
    NextAvailable = "any",
    NextAvailableSouthAustralia = "anySA",
    Taxi = "taxi",
    StationWagon = "wagon",
    Wheelchair = "wheelchair",
    SilverService = "silver",
    MaxiTaxi = "maxi",
    Sedan = "sedan",
    Limo = "limo",
    Parcel = "parcel"
}

/** Metadata about a Service (Vehicle Type) */
export interface ServiceInfo {
    /** IMG URL */
    image: any;

    /** Normal display name */
    displayName: string;

    /** 2-sentence description of this vehicle type for users */
    blurb: string;

    /** Usually just the text "1-4 passengers" */
    short: string;

    /** True for the special case "any / next available" */
    isAny: boolean;

    /** Indicate if this spesific service supports SATSS voucher or not in SA */
    supportSATSS: boolean;

    /** True for the special case "MAXI TAXI" */
    isMaxiTaxi: boolean;

    /** Helps to apply custom behaviour on certain services */
    kind: ServiceKind | null;

    /** URL to the image used as the hero image in track booking cards */
    trackingImage: string;

    /** Whether this vehicle is in the default vehicles list (list of vehicles displayed in the vehicle selector before user enters a pickup address). */
    IsInDefaultList?: boolean;

    /** Initially selected vehicle if the user has not selected any option. */
    IsDefault?: boolean;

    /** The order of this service in the default / standard ordering. */
    StandardSortOrder: number;
}

const services: { [key in ServiceKind]: ServiceInfo } = {
    any: {
        kind: ServiceKind.NextAvailable,
        image: getVehicleImage("Any"),
        displayName: "Next Available", 
        blurb: "In a hurry? We'll get you there as soon as possible by sending the next available car!",
        short: "1-4 passengers",
        isAny: true,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroAny"),
        StandardSortOrder: 1,
    },
    anySA: {
        kind: ServiceKind.NextAvailableSouthAustralia,
        image: getVehicleImage("Any"),
        displayName: "Next Available", 
        blurb: "In a hurry? We'll get you there as soon as possible by sending the next available car! (includes 13LIMO)",
        short: "1-4 passengers",
        isAny: true,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroAny"),
        StandardSortOrder: 2,
    },
    taxi: {
        kind: ServiceKind.Taxi,
        image: getVehicleImage("Sedan"),
        displayName: "Taxi",
        blurb: "Need a cab? We'll get you there as soon as possible by sending the next available taxi (excludes 13LIMO)",
        short: "1-4 passengers",
        isAny: false,
        supportSATSS: true,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroSedan"),
        StandardSortOrder: 3,
    },
    wagon: {
        kind: ServiceKind.StationWagon,
        image: getVehicleImage("Wagon"),
        displayName: "Station Wagon",
        blurb: "If you’re carrying extra luggage, a fold up wheelchair or walker, a bike or a life-size statue of Napoleon, our station wagons are the perfect choice when you need extra space.",
        short: "1-4 passengers",
        isAny: false,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroWagon"),
        StandardSortOrder: 6,
    },
    wheelchair: {
        kind: ServiceKind.Wheelchair,
        image: getVehicleImage("Wheelchair"),
        displayName: "Wheelchair", /** Tom confirmed to be Wheelchair */
        blurb: "Need to travel with your wheels? We have a range of vehicles and qualified Drivers who'll get you there, and can help you in and out of the cab if you need.",
        short: "Accessible Taxi",
        isAny: false,
        supportSATSS: true,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroWheelchair"),
        StandardSortOrder: 8,
    },
    silver: {
        kind: ServiceKind.SilverService,
        image: getVehicleImage("Silver"),
        displayName: "Silver Service",
        blurb: "We all deserve a little Silver Service occasionally. Travel in a luxurious long wheelbase sedan with a professional driver who’ll get you there in style.",
        short: "1-4 passengers",
        isAny: false,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroSilver"),
        StandardSortOrder: 4,
    },
    maxi: {
        kind: ServiceKind.MaxiTaxi,
        image: getVehicleImage("Maxi"),
        displayName: "MAXI TAXI",
        blurb: "Traveling in a group? We'll get you there. Our fleet of MAXI TAXIS are the perfect option.",
        short: "1 - 11 passengers",
        isAny: false,
        supportSATSS: true,
        isMaxiTaxi: true,
        trackingImage: getVehicleImage("HeroMaxi"),
        StandardSortOrder: 9,
    },
    sedan: {
        kind: ServiceKind.Sedan,
        image: getVehicleImage("Sedan"),
        displayName: "Sedan",
        blurb: "If you’re travelling with 4 or less Passengers, our sedans are usually the quickest and most efficient way to get from a to b.",
        short: "1-4 passengers",
        isAny: false,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroSedan"),
        StandardSortOrder: 5,
    },
    limo: {
        kind: ServiceKind.Limo,
        image: getVehicleImage("Limo"),
        displayName: "13LIMO",
        blurb: "Want a sleek ride from a to b? We'll send you the next available 13LIMO.",
        short: "1-4 passengers",
        isAny: false,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroLimo"),
        StandardSortOrder: 7,
    },
    parcel: {
        kind: ServiceKind.Parcel,
        image: getVehicleImage("Parcel"),
        displayName: "Parcel Delivery",       
        blurb: "Need to get something somewhere right now? 13cabs can deliver. If it fits in one of our vehicles, we’ll deliver it door to door for the price of a cab fare.",
        short: "",
        isAny: false,
        supportSATSS: false,
        isMaxiTaxi: false,
        trackingImage: getVehicleImage("HeroParcel"),
        StandardSortOrder: 10,
    }   
};

export default services;