import React, { Fragment } from 'react';
import "./CardRegistration.scss";
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { Dispatch } from '../Dispatch';
import TransparentBlackBg from "../../widgets/transparent-black-background/transparent-black-background.jsx";
import { GetCardRegistrationIFrameRequest, createPaymentCardObj, LoadMyCards } from '../Payment/PaymentHelper'
import { Api } from '../../Services/Api';
import { GetValues } from '../../Config/MyAppConfig';
import { DialogHeaderBackButton } from '../Dialog/CommonDialogHeader';
import { DialogConfigLookup } from '../Dialog/DialogConfig';
import { DialogKind } from '../Dialog/DialogEntities';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { BrandedImage, GetBrandedUrl } from '../Utils/BrandedContentUrls';
import { LogEvent } from '../../utils/LogEvent';
import { ConvertToPaymentOption } from '../Payment/PaymentHandler';

interface CardRegistrationProps {
    IsCardRegistrationPanelOpen: boolean;
    IsMobileDevice: boolean;
}

interface CardRegistrationCssProps {
    CardRegistrationPanelParent: string;
}
interface CardRegistrationState {
    /** if false Show Add Card screen,if true show card registration success screen */
    cardRegistrationSuccess: boolean;
    showSpinner: boolean;
}

class CardRegistration extends React.Component<CardRegistrationProps & CardRegistrationCssProps, CardRegistrationState> {

    constructor(props: CardRegistrationProps & CardRegistrationCssProps) {
        super(props);
        this.state = {
            cardRegistrationSuccess: false,
            // Show the spinner by default until the iFrame content is loaded.
            showSpinner: true
        }
        this.hideCardRegPanel = this.hideCardRegPanel.bind(this);
        this.ReceiveMessage = this.ReceiveMessage.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
    }

    componentDidMount() {
        window.addEventListener("message", this.ReceiveMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.ReceiveMessage, false);
    }

    /** Hide spinner after iFrame content loaded completely. */
    hideSpinner() {
        this.setState({ showSpinner: false });
    }

    /**
     * On add card success, the iframe sends a success message, we check that to determine that the card was successfully added
     * We are making two api calls, one to set the new card as  default and another to get the new card list.
     * We could have avoided GetListOfUserCards() api call by adding the card in the state but booking api applies few rules to card type(not that important), but we wanted to keep it consistent
     */
    ReceiveMessage = async (event: MessageEvent) => {

        const targetUrl = GetValues().Payment!.MpsEndpoint;
        const targetOrigin = new URL(targetUrl).origin;

        if (event.origin === targetOrigin) {
            if (event.data.success) {
                this.setState({ showSpinner: true });
                LogEvent.AddedNewPaymentCard();
                const newPaymentCard = createPaymentCardObj(event.data.card);
                newPaymentCard.IsDefault = true;
                await Api.Payment.EditCard(newPaymentCard);
                await LoadMyCards();
                this.setState({ cardRegistrationSuccess: true, showSpinner: false });
                const paymentOption = ConvertToPaymentOption(newPaymentCard);
                Dispatch.Booking.PaymentMethod(paymentOption);
            }
        } 
    }

    /**
     * Hide the card Registration Panel
     */
    hideCardRegPanel() {
        this.setState({ cardRegistrationSuccess: false });
        Dispatch.Payment.ToggleCardRegistrationPanel(false);
    }

    /**
     * Add another card, open the Iframe again
     */
    addAnotherCard() {
        this.setState({ cardRegistrationSuccess: false });
    }

    getCardRegistrationPanelClass() {
        return this.props.CardRegistrationPanelParent === 'BookingForm' ? "cardRegistrationPanel cardRegistrationPanelBookingForm" : "cardRegistrationPanel cardRegistrationPanelDefaultLocation";
    }

    /**
     * This panel is visible ,once the card registration is successful
     */
    cardRegistrationSuccessPanel = () => {
        return (
            <div className="cardRegistrationSuccessFrame">
                <div className="cardRegistrationSuccessHeaderMessage">Thanks, your card <br></br>has been added.</div>
                <img className="PayIllustrationIcon" src={GetBrandedUrl(BrandedImage.AddPaymentCardIllustration)} />
                <button onClick={() => this.addAnotherCard()} className="AddAnotherCardButton">Add another card</button>
                <button onClick={() => this.hideCardRegPanel()} className="CardRegistrationDoneButton">I’m good to go</button>
            </div>
        );
    }

    /**
     * loading spinner
     */
    loadingComponent = () => {
        const loadingImgClass = this.props.CardRegistrationPanelParent === 'BookingForm' ? "cardRegistrationLoadingBookingForm" : "cardRegistrationLoadingDefaultLocation";
        return (
            <div style={{ zIndex: 221, position: "fixed" }}>
                <TransparentBlackBg onClickAction={this.hideCardRegPanel} />
                <img className={loadingImgClass} alt="RegisteringCard" src={getContentUrl(ContentURL.images.Loading)} />
            </div >
        )
    }


    render() {
        return (
            this.props.IsCardRegistrationPanelOpen &&
            <Fragment>
                {this.state.showSpinner && this.loadingComponent()}
                <div className={this.getCardRegistrationPanelClass()}>
                    {
                        this.props.IsMobileDevice && !this.state.cardRegistrationSuccess && <DialogHeaderBackButton Config={DialogConfigLookup(DialogKind.AddPaymentCard)!}  />
                    }
                    {
                        !this.props.IsMobileDevice && <img onClick={this.hideCardRegPanel} className="cardRegistrationPanelClose" src={getContentUrl(ContentURL.images.buttons.greyX)} />
                    }
                    {!this.state.cardRegistrationSuccess ?
                        <iframe className="cardRegistrationFrame" scrolling="no" src={GetCardRegistrationIFrameRequest()} onLoad={this.hideSpinner} /> : this.cardRegistrationSuccessPanel()}
                </div>
                <div className="cardRegistratioTransparentBlackBg"><TransparentBlackBg onClickAction={this.hideCardRegPanel} /></div>
            </Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState): CardRegistrationProps {
    return { 
        IsCardRegistrationPanelOpen: state.payment.IsCardRegistrationPanelOpen,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}


export default connect(mapStateToProps)(CardRegistration);