import moment from "moment";
import { Dispatch } from "../../Dispatch";
import { TryRestoreLoginSession, TryRestoreLoginSessionFromSingleSignOn } from '../../Authentication/LoginSession';
import { Config } from '../../../Config/Config';
import { GetIPLocationFast } from '../../../modules/IPLocation/IPLocation';
import { LocationData } from '../../../modules/Location/Entities';
import { FeatureId } from "../../WhatsNew/FeaturesEntities";
import { GetMyDevice } from "../../../Config/MyAppConfig";
import { DeviceKind } from "../../../Config/Entities/DeviceKind";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { AuthToken, LoginStatusKind } from "../../Authentication/AuthEntities";
import appstore from "../../../appStore";
import CredentialsController from "../../Authentication/Login/CredentialsController";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { MyStorage } from "../../../Storage";
import { LoadLinks } from "../../TrackingLinks/LoadLinks";
import { BookingInfo } from "../MyBookingEntities";
import { BookingLink } from "../../TrackingLinks/Redux/TrackingLinksState";
import { LoadLegalDocuments } from "../../LegalDocuments/LoadLegalDocuments";
import { LoadAllVehicles } from "../../Condition/LoadAllVehicles";
import { DialogKind } from "../../Dialog/DialogEntities";
import { LoadNewOdrdTripId } from "../../Booking/LoadNewOdrdTripId";
import { PreviewFeatures } from "../../Features/PreviewFeatures";

/** 
 *  Work to do when the application starts, i.e. App.tsx componentDidMount. 
 *  Load data from local storage and load profile related data.
 *  This code must satisfy guest and (previously) logged in users.
 */
export async function DoStartupWork() {

    // don't do work in the hidden iframe
    if (IsAppInNonUserMode()) return;

    LoadNewOdrdTripId();

    ConsiderEnablingMobileLayout();

    // intentially not awaited; it can run in the background
    StartLoadingIPLocation();

    const token = RestoreAuth0Token();
    RestoreMyBookings();
    RestoreMyAcknowledgedFeatures();

    if (token) {
        await TryRestoreLoginSession(token);
    }
    else if (FeatureFlags.SingleSignOn) {
        await TryRestoreLoginSessionFromSingleSignOn();
    }

    if (FeatureFlags.PreviewFeatures) await PreviewFeatures.Reload();

    // Consider force login (open login dialog) after trying to restore the login session.
    ConsiderForceLogin();
    HandleLoginSignupUrls();

    // Load all vehicles list for the brand for V2 API.
    if (FeatureFlags.BookingApiV2) LoadAllVehicles();

    // Load latest legal documents. Don't await.
    if (FeatureFlags.LegalDocumentsV2) {
        LoadLegalDocuments();
    }

    // Consider migrating v1 tracking links in local storage (if available) to v2.
    ConsiderTrackingLinksMigration();

    // No need to await this. Can run in the background.
    LoadLinks();

    ConsiderShowingPriceGuaranteePopupAd();

    ShowDeprecationWarningForIE();    
}

/**
 * During Unobtrusive Login, a duplicate copy of the website will be loaded inside a hidden iFrame. This copy just does the login / redirect, then ends.
 * We don't want it doing startup tasks, e.g. calling 10 APIs to load data.
 * We detect the unobtrusive login app copy by the URLs it is known to use.
 */
function IsAppInNonUserMode(): boolean {

    for (let path in Config.UnobtrusiveLoginUrls) {
        if (window.location.pathname === path) return true;
    }
    for (let path in Config.StaticContentURLs) {
        if (window.location.pathname === path) return true;
    }

    return false;
}

/** Activate mobile UI layout mode if the client's device type suits it. */
function ConsiderEnablingMobileLayout() {

    const device = GetMyDevice();

    if (device === DeviceKind.Phone) {
        Dispatch.UILogicControl.LayoutMode(UILayoutMode.Mobile);
    }
}

/** 
 *  Guess the user's location from their IP address and push that into Redux.
 *  This won't be awaited; it is safe to let run in the background.
 */
async function StartLoadingIPLocation(): Promise<void> {

    const result: LocationData = await GetIPLocationFast;
    Dispatch.Location.ipGeolocationComplete(result);
}

/** Load persisted "Auth0 token" data from HTML local storage. */
function RestoreAuth0Token(): AuthToken | null {

    const authToken = MyStorage.AuthToken.LoadData()
    if (authToken) Dispatch.Auth.Auth0TokenCreated(authToken);

    return authToken;
}

/** 
 *  Load persisted "My Bookings" data from HTML local storage. 
 *  Bookings that are "old" won't be restored.
 *  This is used to persist bookings for Guest users. Signed in users will also use it as a performance boost; we will refresh their bookings from the API.
 */
function RestoreMyBookings() {

    const allBookings = MyStorage.Bookings.LoadData() || [];
    const usefulBookings = allBookings.filter(IsBookingStillUseful);

    Dispatch.MyBookings.DataRefresh(usefulBookings);
}

/** Returns true if the specified booking is still relevant to the user. 
 * It is be used to discard old bookings during restore. */
function IsBookingStillUseful(booking: BookingInfo): boolean {
    const requestedTime = moment(booking.Time, "MM/DD/YYYY HH:mm:SS");
    const cutoffTime = moment().add(-3, "hours");

    return requestedTime > cutoffTime;
}

/** Load persisted "My Acknowledged Features" data from HTML local storage.
 *  It is used to display new and acknowledged features */
function RestoreMyAcknowledgedFeatures() {

    const allNewFeatures: FeatureId[] = [];
    const allAcknowledgedFeatures = MyStorage.FeatureList.LoadData() || [];

    // Iterate through the features
    Object.keys(FeatureId).map(feature => {

        const parsedValue = parseInt(feature);

        // Do not proceed, if the element is of type string
        if (isNaN(parsedValue)) return;

        if (!allAcknowledgedFeatures.includes(parsedValue)) {
            allNewFeatures.push(parsedValue);
        }
    });

    Dispatch.Features.SaveNewFeatureList(allNewFeatures);
    Dispatch.Features.SaveAcknowledgedFeatureList(allAcknowledgedFeatures);
}

/**
 * Handles the "?logIn=true" and "?signUp=true" URL query strings.
 * These will open the login and signup dialogs respectively.
 * External links e.g. from the Wordpress site might use these links.
 */
function HandleLoginSignupUrls() {

    // no need if you are already logged in
    if (appstore.getState().authentication.LoginStatus !== LoginStatusKind.LoggedOut) return;

    const querytStr = window.location.search;

    if (/logIn=true/i.test(querytStr)) {
        new CredentialsController().DoLogin();
    }
    else if (/signUp=true/i.test(querytStr)) {
        // When sign up is not allowed, open Login dialog for this URL.
        if (FeatureFlags.NoSignup) {
            new CredentialsController().DoLogin();
        } else {
            new CredentialsController().DoSignup();
        }        
    }
}

/** 
 * Decide whether to force login and if true, open the Login dialog.
 * This feature is used to prevent guest bookings in some brands like Cabcharge.
 * */
function ConsiderForceLogin() {
    // Already logged in, no need to proceed.
    if (appstore.getState().authentication.LoginStatus !== LoginStatusKind.LoggedOut) return;

    // Force login is not enabled. i.e. can make guest bookings
    if (!FeatureFlags.ForceLogin) return;

    // Open Login dialog
    new CredentialsController().DoLogin();
}

/** One-off data migration from trackingLinks to trackingLinksV2 to avoid issues with incompatible data types. */
function ConsiderTrackingLinksMigration() {
    const v1Links = MyStorage.TrackingLinks.LoadData();

    if (v1Links) {
        const v2Links: BookingLink[] = v1Links.map((link) => ({
            LinkVersion: 1,
            BookingKey: link
        }));

        MyStorage.TrackingLinksV2.StoreData(v2Links);

        // Clear V1 so this will be one-off migration.
        MyStorage.TrackingLinks.ClearData();
    }
}

/** A one-time popup advertising the price guarantee feature */
function ConsiderShowingPriceGuaranteePopupAd() {

    // PG not supported
    if (!FeatureFlags.PriceGuarantee) return;

    // already seen
    if (MyStorage.HasSeenPriceGuaranteeModalFAQ.LoadData() === true) return;

    // user is visiting a tracking link; probably not booking
    if (appstore.getState().trackingLinks.NewFromUrl) return;

    // disable PG pop up temporarily 
    //Dispatch.Dialog.ShowDialog(DialogKind.PriceGuaranteeFAQ);
}

/** A one-time popup to warning user about IE 11 is deprecated */
function ShowDeprecationWarningForIE() {
    
    // user agent name for IE is either MSIE or Trident
    if ((navigator.userAgent.includes("MSIE")) || (navigator.userAgent.includes("Trident"))) {
        return Dispatch.Dialog.ShowDialog(DialogKind.IE11DeprecationWarning);
    }
}   