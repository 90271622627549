import React from "react";
import { connect } from "react-redux";
import './StepControl.scss';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { ApplicationState } from "../../../appState";
import { BookingFormKind, UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";
import { FormStepLabel } from "./FormStepLabel";
import { PreviewFeatureId } from "../../Features/FeatureEntities";
import { OptionalUI } from "../OptionalParts/OptionalUI";

interface StepControlProps {    
    StepKind: FormStepLabel;
}

/**
 * To describe which step it is in booking form or parcel booking form
 */
class StepControl extends React.Component<StepControlProps & ReduxProps> {

    calculateStepNumber = (stepKind: FormStepLabel) : { totalSteps: number,  stepNumber: number } => {
        // Construct a map for each step kind and its calculated step number
        let table = new Map<FormStepLabel, number>();

        if (this.props.IsNewParcelFeatureEnabled) {
            // parcel widget feature on and booking on account toggle on
            if (this.props.IsBookingOnAccount) {

                table.set(FormStepLabel.SelectYourService, 1);
                table.set(FormStepLabel.AccountDetails, 2);

                if (this.props.ActiveWidget === BookingFormKind.PassengerBooking) {

                    table.set(FormStepLabel.BookingDetails, 3);
                    table.set(FormStepLabel.ContactDetails, 4);
                    table.set(FormStepLabel.DriverInstruction, 5);   
                }
                else {
                    // parcel booking
                    table.set(FormStepLabel.PickupDetails, 3);
                    table.set(FormStepLabel.DropOffDetails, 4);
                }
                
            } else {
                // parcel widget feature on and booking on account toggle off
                table.set(FormStepLabel.SelectYourService, 1);

                if (this.props.ActiveWidget === BookingFormKind.PassengerBooking) {

                    table.set(FormStepLabel.BookingDetails, 2);
                    table.set(FormStepLabel.ContactDetails, 3);
                    table.set(FormStepLabel.DriverInstruction, 4);   
                    table.set(FormStepLabel.PaymentMethod, 5);   
                }
                else {
                    // parcel booking
                    table.set(FormStepLabel.PickupDetails, 2);
                    table.set(FormStepLabel.DropOffDetails, 3);
                    table.set(FormStepLabel.PaymentMethod, 4); 
                }
            }
        }
        else {
            // only passenger booking form and booking on account toggle on
            if (this.props.IsBookingOnAccount) {

                table.set(FormStepLabel.AccountDetails, 1);
                table.set(FormStepLabel.BookingDetails, 2);
                table.set(FormStepLabel.ContactDetails, 3);
                table.set(FormStepLabel.DriverInstruction, 4);
            }
            else {
                // only passenger booking form and booking on account toggle off
                table.set(FormStepLabel.BookingDetails, 1);
                table.set(FormStepLabel.ContactDetails, 2);
                table.set(FormStepLabel.DriverInstruction, 3);
                table.set(FormStepLabel.PaymentMethod, 4);
            }
        }

        if (this.props.IsDeliveryOptionShown) {
            table.set(FormStepLabel.DeliveryOptions, table.size + 1);
        }

        const totalSteps = table.size;
        return { totalSteps, stepNumber: table.get(stepKind)! };
    } 
    
    render() {
        
        const isTemplateModeOn = this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking;

        const { totalSteps, stepNumber } = this.calculateStepNumber(this.props.StepKind);

        // Hide step details for booking template
        const stepDetail = !isTemplateModeOn ? `Step ${stepNumber} of ${totalSteps} ` : "";

        // Display black text colour for booking template
        const textColorClass = !isTemplateModeOn ? "brand-color" : "";

        return (            
            <div className="form-section-header">
                { stepDetail }
                <span className={`${textColorClass}`}>
                    { this.props.StepKind }
                </span>
            </div>            
        )
    }
}

// #region Redux

interface ReduxProps {
    IsBookingOnAccount: boolean;
    BookingWidgetMode: BookingWidgetModeKind;
    IsMobileDevice: boolean;
    UserProfile?: SimpleUserProfile;
    ActiveWidget: BookingFormKind;
    IsNewParcelFeatureEnabled: boolean;
    IsDeliveryOptionShown: boolean;
}

function mapStateToProps(state: ApplicationState): ReduxProps {
    return {
        UserProfile: state.authentication.UserProfile,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile),
        ActiveWidget: state.uiLogicControl.BookingForm.ActiveBookingForm,
        IsNewParcelFeatureEnabled: !!state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget],
        IsDeliveryOptionShown: OptionalUI.DeliveryOption(state),
    }  
}

// #endregion

export default connect (mapStateToProps)(StepControl);