import { combineReducers } from 'redux';
import { LocationReducer } from '../modules/Location/Reducer';
import { DialogReducer } from '../modules/Dialog/DialogReducer';
import { ConditionReducerV2 } from '../modules/Condition/Redux/ConditionDispatcherV2';
import { TabReducer } from "../widgets/NavBar/TabDispatch";
import { ApiFailureTestingReducer } from '../modules/ApiFailureTesting/FailureTestingReducer';
import { TncPrivacyReducer } from '../modules/TncPrivacy/TncPrivacyReducer';
import { UserProfileNavReducer } from "../modules/User/UserProfileNavReducer";
import { UILogicControlReducer } from "../modules/UILogicControl/UILogicControlDispatch";
import { MyBookingsReducerV2 } from '../modules/MyBookings/MyBookingsDispatchV2';
import { LoginValidationReducer } from '../modules/LoginValidation/LoginValidationDispatch';
import { BookingTemplatesReducer } from '../modules/BookingTemplate/BookingTemplatesDispatch';
import { PaymentReducerV2 } from '../modules/Payment/PaymentDispatcherV2';
import { Dialog2Reducer } from '../modules/Dialog2/Redux/Dialog2Dispatch';
import { AuthReducerV2 } from '../modules/Authentication/AuthDispatchV2';
import { VerificationReducerV2 } from '../modules/Verification/VerificationDispatchV2';
import { FeaturesReducer } from '../modules/WhatsNew/FeaturesDispatch';
import { TrackingLinksReducer } from '../modules/TrackingLinks/Redux/TrackingLinksDispatch';
import { BookingReducerV2 } from '../modules/Booking/Redux/BookingDispatchV2';
import { LegalDocumentsReducer } from '../modules/LegalDocuments/LegalDocumentsDispatch';
import { AddressPointsReducer } from '../modules/AddressPoints/Redux/AddressPointsDispatch';
import { GoogleMapReducerV2 } from '../modules/GoogleMap/GoogleMapDispatchV2';

export default combineReducers({
    location: LocationReducer,
    googleMap: GoogleMapReducerV2,
    dialog: DialogReducer,
    booking: BookingReducerV2,
    condition: ConditionReducerV2,
    tabs: TabReducer,
    apiFailureTesting: ApiFailureTestingReducer,
    authentication: AuthReducerV2,
    myBookings: MyBookingsReducerV2,
    verification: VerificationReducerV2,
    tncPrivacy: TncPrivacyReducer,
    payment: PaymentReducerV2,
    userProfileNav: UserProfileNavReducer,
    uiLogicControl: UILogicControlReducer,
    bookingTemplates: BookingTemplatesReducer,
    loginValidation: LoginValidationReducer,
    dialog2: Dialog2Reducer,
    features: FeaturesReducer,
    trackingLinks: TrackingLinksReducer,
    legalDocuments: LegalDocumentsReducer,
    addressPoints: AddressPointsReducer
});
