import appstore from "../../appStore";
import { AsyncLoadState, DataLoadingStatus, FareRequestInput, VehicleTypeV2 } from "./Redux/ConditionEntities";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { FareEstimateRequestV1, FareLocation, TaxiFareEstimateRequestV2, TaxiFareEstimateResponseV2, TaxiFareResponseV2 } from "../../Services/FareEntities";
import { ApplicationState } from "../../appState";
import { ComputeAsyncLoadState } from "./ComputeAsyncLoadStats";
import { RoundTime } from '../Fare/FareHelper';
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { AsyncUpdateOutcome } from "./AsyncUpdateOutcome";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { ServiceResult } from "../../Services/ServiceEntities";
import { ConsiderToShowPriceGuaranteeTip } from "../Booking/Widget/ConsiderToShowPriceGuaranteeTip";
import { DateTime } from "luxon";
import { decode, LatLngTuple } from "@googlemaps/polyline-codec";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";

/** Check whether it is possible to do a Fare data load, then perform it if necessary. 
 Returns undefined when no action will occur due to prerequisites not being met.
 Otherwise, returns a promise that can be awaited when the work completes.  
 NOTE: Since we allow long distance bookings, we now request fare estimates for long distance trips. */
export async function ConsiderFareUpdate(): Promise<AsyncUpdateOutcome> {

    const appState = appstore.getState();

    // input not ready?
    const proposedInput = ComputeFareRequestInput(appState);
    if (proposedInput === null) return AsyncUpdateOutcome.InputsNotReady;
    
    const pickupCheck = appState.booking.PickupServiceCheck;
    if (pickupCheck.status !== ServiceCheckStatus.KnownGood) return AsyncUpdateOutcome.InputsNotReady;
    
    if (!FeatureFlags.BookingApiV2) {
        // requisite: conditions loaded OK
        const conditionLoad = appState.condition.LoadingStatus;
        if (conditionLoad.Status !== DataLoadingStatus.Idle) return AsyncUpdateOutcome.InputsNotReady;
        if (conditionLoad.LastInput === null) return AsyncUpdateOutcome.InputsNotReady;
        if (conditionLoad.LastInput !== pickupCheck.suburbId) return AsyncUpdateOutcome.InputsNotReady;
    }

    // No need to load fare if the selected time is in past. The UI displays an error for this and booking is blocked.
    if ((proposedInput.Time.IsImmediate === false) && proposedInput.Time.RequestedDate < DateTime.now()) {
        return AsyncUpdateOutcome.InputsNotReady;
    }

    // drop identical requests (only if fare is available from the previous request)
    const currentStatus = appState.condition.FareLoadStatus;

    if (IsDuplicateRequest(currentStatus, proposedInput)) {
        return AsyncUpdateOutcome.InputsUnchanged;
    }

    // OK! (return awaitable promise)
    return await PerformFareLoad(proposedInput);
}

/** Generates a FareRequestInput from the current store. Returns null if any required input is null. */
function ComputeFareRequestInput(appState: ApplicationState): FareRequestInput | null {

    const pickup = appState.booking.Pickup.Address;
    if (!pickup) return null;

    const dropoff = appState.booking.Dropoff.Address;
    if (!dropoff) return null;

    const timing = appState.booking.BookingTimeV2;
    const effectiveTime: DateTime = timing.IsImmediate ? DateTime.now() : timing.RequestedDate;

    return {
        Pickup: pickup,
        Dropoff: dropoff,
        Time: timing,
        EffectiveTime: effectiveTime,
        RequestTime: DateTime.now(),
    };
}

/**
 * Returns true if the proposed fare request is a duplicate of the previous (possibly still in progress) request, and can be ignored.
 * Duplicates can be allowed for e.g. error retries.
 */
function IsDuplicateRequest(existingState: AsyncLoadState<FareRequestInput>, proposedRequest: FareRequestInput): boolean {

    // no previous request
    if (existingState.LastInput == null) return false;

    // new request is manifestly different
    if (AreInputsEqual(existingState.LastInput, proposedRequest) == false) return false;

    // error retries are allowed, i.e. not duplicates
    if (existingState.Status === DataLoadingStatus.Error) return false;

    // existing data has definitely loaded correctly
    if (appstore.getState().condition.SelectedCondition.FareDetails) return true;

    // simultaneous duplicate
    const timeDiff = existingState.LastInput.RequestTime.diff(proposedRequest.RequestTime);
    if (Math.abs(timeDiff.as("seconds")) < 1) return true;

    // this is another retry case (first request stuck / not returning data)
    return false;
}

/** Returns true if the following FareRequestInput objects are identical. Ths is used to prevent duplicate loads. It's a bit involved since there are three fields to check. */
function AreInputsEqual(existing: FareRequestInput, other: FareRequestInput): boolean {

    if (existing.Pickup.GoogleMapsPlaceId !== other.Pickup.GoogleMapsPlaceId) return false;
    if (existing.Dropoff.GoogleMapsPlaceId !== other.Dropoff.GoogleMapsPlaceId) return false;

    // now it just comes down to timing
    const difference = existing.EffectiveTime.diff(other.EffectiveTime);

    // don't sweat the small stuff
    if (Math.abs(difference.as("minutes")) < 15) return true;

    // original behaviour follows
    if (existing.Time.IsImmediate !== other.Time.IsImmediate) return false;

    // future bookings: check time component
    if (!existing.Time.IsImmediate && !other.Time.IsImmediate) {

        if (RoundTime(existing.Time.RequestedDate).equals(RoundTime(other.Time.RequestedDate)) === false) {
            return false;
        }
    }

    // all good!
    return true;
}

/** Perform the GetFare API call, including setting the state to in progress and then complete / error. */
async function PerformFareLoad(input: FareRequestInput): Promise<AsyncUpdateOutcome> {

    // Clear old fare estimate
    Dispatch.Condition.ClearFareEstimate();

    Dispatch.Condition.FareLoadStatus({ Status: DataLoadingStatus.InProgress, LastInput: input });

    let result: ServiceResult<TaxiFareEstimateResponseV2 | TaxiFareResponseV2>;

    if (FeatureFlags.BookingApiV2) {
        result = await GetFareFromV2Api();
    }
    else {
        result = await GetFareFromV1Api(input);
    }

    // check for input drift, which would make the result no longer meaningful
    const inputNow = ComputeFareRequestInput(appstore.getState());
    if ((inputNow === null) || !AreInputsEqual(inputNow, input)) {
        return AsyncUpdateOutcome.InputChangedDuringLoad;
    }

    // update loading state
    const newState = ComputeAsyncLoadState(result, input);
    Dispatch.Condition.FareLoadStatus(newState);

    // update actual data
    if (result.isSuccess) {
        SaveFareInStore(result.value);
        ConsiderToShowPriceGuaranteeTip();
        return AsyncUpdateOutcome.Success;
    } 
    else {
        return AsyncUpdateOutcome.LoadFailed;
    }
}

// #region Get Fare From API

/**
 * Call the V1 (Booking API) for getting the fare estimate
 */
async function GetFareFromV1Api(input: FareRequestInput): Promise<ServiceResult<TaxiFareResponseV2>> {

    // Intentionally using V2 time because it has the proper format and both V1 and V2 have the same value.
    const state = appstore.getState();
    const bookingTime = state.booking.BookingTimeV2;

    // NULL for now bookings.
    let departureTime: string | null = null;

    if (bookingTime.IsImmediate === false) {
        departureTime = bookingTime.RequestedDate.toISO();
    }

    const pickupLocation: FareLocation = {
        Latitude: input.Pickup.GeoLocation.Latitude,
        Longitude: input.Pickup.GeoLocation.Longitude,
        GoogleMapsPlaceId: input.Pickup.GoogleMapsPlaceId
    };

    const dropoffLocation: FareLocation = {
        Latitude: input.Dropoff.GeoLocation.Latitude,
        Longitude: input.Dropoff.GeoLocation.Longitude,
        GoogleMapsPlaceId: input.Dropoff.GoogleMapsPlaceId
    };    

    const request: FareEstimateRequestV1 = {
        Pickup: pickupLocation,
        Dropoff: dropoffLocation,
        PickupSuburbId: state.condition.LoadingStatus.LastInput!, // This method is called only if LastInput is not null.
        DepartureTime: departureTime,
        GoogleOdrdTripId: null, // populated below
    };

    // ODRD Trip ID
    if (OptionalUI.SendOdrdTripId(state)) {
        request.GoogleOdrdTripId = state.booking.GoogleOdrdTripId;
    }

    return await Api.Fare.GetFareV2(request);
}

/**
 * Call the V2 (GB API) for getting the fare estimate
 */
async function GetFareFromV2Api(): Promise<ServiceResult<TaxiFareEstimateResponseV2>> {
    
    const { Pickup, Dropoff, BookingTimeV2 } = appstore.getState().booking;

    const bookingDetails: TaxiFareEstimateRequestV2 = {
        Pickup: Pickup.Address!,
        Dropoff: Dropoff.Address!,
        VehicleType: VehicleTypeV2.StandardTaxi
    }

    // Future booking
    if (BookingTimeV2?.IsImmediate === false && BookingTimeV2?.RequestedDate) {
        bookingDetails.FutureRequestedTime = BookingTimeV2.RequestedDate.toISO();
    }

    return await Api.MakeBooking.GetFare(bookingDetails);
}

// #endregion

// #region Save Fare In Store

/** Save the fare details into the condition list */
function SaveFareInStore(fareResponse: TaxiFareEstimateResponseV2 | TaxiFareResponseV2): void {

    if (FeatureFlags.BookingApiV2) {
        SaveV2FareInStore(<TaxiFareEstimateResponseV2>fareResponse);
    }
    else {
        SaveV1FareInStore(<TaxiFareResponseV2>fareResponse);
    }
}

/** Store the fare result from the V1 Booking API */
function SaveV1FareInStore(fare: TaxiFareResponseV2) {

    Dispatch.Condition.ApplyFareEstimate(fare);

    // store the route as well
    const routeUsingTuple = decode(fare.FareDetails.Polyline);
    const routeUsingLatLng = routeUsingTuple.map(ConvertLatLongTupleToGoogleFormat);

    Dispatch.GoogleMap.PlannedRoute(routeUsingLatLng);
}

/**
 * Input = data format from the polyline-codec library: [number, number].
 * Output = data format for Google Maps: { lat, lng }.
 */
function ConvertLatLongTupleToGoogleFormat(tuple: LatLngTuple): google.maps.LatLngLiteral {
    return {
        lat: tuple[0],
        lng: tuple[1],
    };
}

/** Store the fare result from the V2 Booking API */
function SaveV2FareInStore(fare: TaxiFareEstimateResponseV2) {

    Dispatch.Condition.ApplyFareEstimateV2(fare);
}

// #endregion